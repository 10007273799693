import service from '@/libs/request'

const BaseUrl = 'https://theory.lanqingnet.com/'

//学员管理
export function getAnalysisUserList(data){
    return service({
        url:`${BaseUrl}/admin/Analysis/getUserList`,
        method: 'post',
        data
    })
}

export function get_active_list(data) {
    return service({
        url: `${BaseUrl}/admin/Analysis/getActiveList`,
        method: 'post',
        data
    })
}

export function get_active_show(data) {
    return service({
        url: `${BaseUrl}/admin/Analysis/getActiveShow`,
        method: 'post',
        data
    })
}

export function change_active_status(data) {
    return service({
        url: `${BaseUrl}/admin/Analysis/changeActiveStatus`,
        method: 'post',
        data
    })
}

export function active_message(data) {
    return service({
        url: `${BaseUrl}/admin/Analysis/messageActive`,
        method: 'post',
        data
    })
}

export function del_active(data) {
    return service({
        url: `${BaseUrl}/admin/Analysis/delActive`,
        method: 'post',
        data
    })
}

export function create_active(data) {
    return service({
        url: `${BaseUrl}/admin/Analysis/createActive`,
        method: 'post',
        data
    })
}

//编辑科目
export function get_subject(data) {
    return service({
        url: `${BaseUrl}/admin/Analysis/getSubject`,
        method: 'post',
        data
    })
}

export function add_subject(data) {
    return service({
        url: `${BaseUrl}/admin/Analysis/addSubject`,
        method: 'post',
        data
    })
}

export function edit_subject(data) {
    return service({
        url: `${BaseUrl}/admin/Analysis/editSubject`,
        method: 'post',
        data
    })
}

export function show_subject(data) {
    return service({
        url: `${BaseUrl}/admin/Analysis/showSubject`,
        method: 'post',
        data
    })
}

export function del_subject(data) {
    return service({
        url: `${BaseUrl}/admin/Analysis/delSubject`,
        method: 'post',
        data
    })
}

export function getSection(data) {
    return service({
        url: `${BaseUrl}/admin/Analysis/getSection`,
        method: 'post',
        data
    })
}

export function addSection(data) {
    return service({
        url: `${BaseUrl}/admin/Analysis/addSection`,
        method: 'post',
        data
    })
}

export function delSection(data) {
    return service({
        url: `${BaseUrl}/admin/Analysis/delSection`,
        method: 'post',
        data
    })
}

export function editSection(data) {
    return service({
        url: `${BaseUrl}/admin/Analysis/editSection`,
        method: 'post',
        data
    })
}

export function orderSection(data) {
    return service({
        url: `${BaseUrl}/admin/Analysis/orderSection`,
        method: 'post',
        data
    })
}

export function get_orderList(data) {
    return service({
        url: `${BaseUrl}/admin/Analysis/orderlist`,
        method: 'post',
        data
    })
}

export function get_allSubject(data) {
    return service({
        url: `${BaseUrl}/admin/Analysis/allsubject`,
        method: 'post',
        data
    })
}

export function delOrder(data) {
    return service({
        url: `${BaseUrl}/admin/Analysis/delOrder`,
        method: 'post',
        data
    })
}

export function sendOrder(data) {
    return service({
        url: `${BaseUrl}/admin/Analysis/sendOrder`,
        method: 'post',
        data
    })
}


// =============================

export function add_exam_bag(data) {
    return service({
        url: `${BaseUrl}/admin/Analysis/addExamBag`,
        method: 'post',
        data
    })
}

export function del_exam_bag(data) {
    return service({
        url: `${BaseUrl}/admin/Analysis/delExamBag`,
        method: 'post',
        data
    })
}

export function edit_exam_bag(data) {
    return service({
        url: `${BaseUrl}/admin/Analysis/editExamBag`,
        method: 'post',
        data
    })
}

export function getExamDetail(data) {
    return service({
        url: `${BaseUrl}/admin/Analysis/getExamDetail`,
        method: 'post',
        data
    })
}

export function get_exam_list(data) {
    return service({
        url: `${BaseUrl}/admin/Analysis/getExamBag`,
        method: 'post',
        data
    })
}

export function backadd_active(data) {
    return service({
        url: `${BaseUrl}/admin/Analysis/addActivetime`,
        method: 'post',
        data
    })
}

export function getPaper(data) {
    return service({
        url: `${BaseUrl}/admin/Analysis/getPaper`,
        method: 'post',
        data
    })
}

export function getPaperDetail(data) {
    return service({
        url: `${BaseUrl}/admin/Analysis/getPaperDetail`,
        method: 'post',
        data
    })
}

export function addAnalysisPaperQuestions(data) {
    return service({
        url: `${BaseUrl}/admin/Analysis/addAnalysisPaperQuestions`,
        method: 'post',
        data
    })
}

export function getAnalysisPaperQuestions(data) {
    return service({
        url: `${BaseUrl}/admin/Analysis/getAnalysisPaperQuestions`,
        method: 'post',
        data
    })
}

export function addPaper(data) {
    return service({
        url: `${BaseUrl}/admin/Analysis/addPaper`,
        method: 'post',
        data
    })
}

export function delPaper(data) {
    return service({
        url: `${BaseUrl}/admin/Analysis/delPaper`,
        method: 'post',
        data
    })
}

export function editPaper(data) {
    return service({
        url: `${BaseUrl}/admin/Analysis/editPaper`,
        method: 'post',
        data
    })
}
//getPaper, addPaper, delPaper, editPaper

export function addParagraph(data) {
    return service({
        url: `${BaseUrl}/admin/Analysis/addParagraph`,
        method: 'post',
        data
    })
}

export function getParagraph(data) {
    return service({
        url: `${BaseUrl}/admin/Analysis/getParagraph`,
        method: 'post',
        data
    })
}

export function editParagraph(data) {
    return service({
        url: `${BaseUrl}/admin/Analysis/editParagraph`,
        method: 'post',
        data
    })
}

export function addAnalysisQuestions(data) {
    return service({
        url: `${BaseUrl}/admin/Analysis/addAnalysisQuestions`,
        method: 'post',
        data
    })
}

export function getAnalysisQuestions(data) {
    return service({
        url: `${BaseUrl}/admin/Analysis/getAnalysisQuestions`,
        method: 'post',
        data
    })
}

export function get_crouse_list(data){
    return service({
        url: `${BaseUrl}/admin/Analysis/getCourse`,
        method: 'post',
        data
    })
}

export function add_crouse_bag(data){
    return service({
        url: `${BaseUrl}/admin/Analysis/addCourse`,
        method: 'post',
        data
    })
}

export function del_crouse_bag(data){
    return service({
        url: `${BaseUrl}/admin/Analysis/delCourse`,
        method: 'post',
        data
    })
}
export function edit_crouse_bag(data){
    return service({
        url: `${BaseUrl}/admin/Analysis/editCourse`,
        method: 'post',
        data
    })
}

export function get_material_list(data){
    return service({
        url: `${BaseUrl}/admin/Analysis/getMaterial`,
        method: 'post',
        data
    })
}

export function add_material(data){
    return service({
        url: `${BaseUrl}/admin/Analysis/addMaterial`,
        method: 'post',
        data
    })
}

export function del_material(data){
    return service({
        url: `${BaseUrl}/admin/Analysis/delMaterial`,
        method: 'post',
        data
    })
}
export function edit_material(data){
    return service({
        url: `${BaseUrl}/admin/Analysis/editMaterial`,
        method: 'post',
        data
    })
}

export function get_notice_list(data){
    return service({
        url: `${BaseUrl}/admin/Analysis/getNotice`,
        method: 'post',
        data
    })
}

export function add_notice(data){
    return service({
        url: `${BaseUrl}/admin/Analysis/addNotice`,
        method: 'post',
        data
    })
}

export function del_notice(data){
    return service({
        url: `${BaseUrl}/admin/Analysis/delNotice`,
        method: 'post',
        data
    })
}
export function edit_notice(data){
    return service({
        url: `${BaseUrl}/admin/Analysis/editNotice`,
        method: 'post',
        data
    })
}

export function get_question_list(data){
    return service({
        url: `${BaseUrl}/admin/Analysis/getQuestion`,
        method: 'post',
        data
    })
}

export function del_question(data){
    return service({
        url: `${BaseUrl}/admin/Analysis/delQuestion`,
        method: 'post',
        data
    })
}

export function get_answer_list(data){
    return service({
        url: `${BaseUrl}/admin/Analysis/getAnswer`,
        method: 'post',
        data
    })
}

export function del_answer(data){
    return service({
        url: `${BaseUrl}/admin/Analysis/delAnswer`,
        method: 'post',
        data
    })
}

///////////////////////////////////

export function get_cate(data) {
    return service({
        url: `${BaseUrl}/admin/SystemSet/getCate`,
        method: 'post',
        data
    })
}

export function get_cate_s(data) {
    return service({
        url: `${BaseUrl}/admin/SystemSet/getCateS`,
        method: 'post',
        data
    })
}

export function del_cate_s(data) {
    return service({
        url: `${BaseUrl}/admin/SystemSet/delCate`,
        method: 'post',
        data
    })
}

export function add_cate_s(data) {
    return service({
        url: `${BaseUrl}/admin/SystemSet/addCate`,
        method: 'post',
        data
    })
}

//账号列表
export function get_users(data) {
    return service({
        url: `${BaseUrl}/admin/SystemSet/getUsers`,
        method: 'post',
        data
    })
}

export function add_user(data) {
    return service({
        url: `${BaseUrl}/admin/SystemSet/addUser`,
        method: 'post',
        data
    })
}

export function edit_user(data) {
    return service({
        url: `${BaseUrl}/admin/SystemSet/editUser`,
        method: 'post',
        data
    })
}

export function del_user(data) {
    return service({
        url: `${BaseUrl}/admin/SystemSet/delUser`,
        method: 'post',
        data
    })
}

export function edit_password(data) {
    return service({
        url: `${BaseUrl}/admin/SystemSet/editPass`,
        method: 'post',
        data
    })
}

//展示营销
export function get_banner() {
    return service({
        url: `${BaseUrl}/admin/SystemSet/getBanner`,
        method: 'post',
    })
}

export function add_banner(data) {
    return service({
        url: `${BaseUrl}/admin/SystemSet/addBanner`,
        method: 'post',
        data
    })
}

export function edit_banner(data) {
    return service({
        url: `${BaseUrl}/admin/SystemSet/editBanner`,
        method: 'post',
        data
    })
}

export function del_banner(data) {
    return service({
        url: `${BaseUrl}/admin/SystemSet/delBanner`,
        method: 'post',
        data
    })
}

export function order_banner(data) {
    return service({
        url: `${BaseUrl}/admin/SystemSet/orderBanner`,
        method: 'post',
        data
    })
}

//科目课程列表
export function get_course(data) {
    return service({
        url: `${BaseUrl}/admin/Subject/getCourse`,
        method: 'post',
        data
    })
}

export function add_course(data) {
    return service({
        url: `${BaseUrl}/admin/Subject/addCourse`,
        method: 'post',
        data
    })
}

export function edit_course(data) {
    return service({
        url: `${BaseUrl}/admin/Subject/editCourse`,
        method: 'post',
        data
    })
}

export function del_course(data) {
    return service({
        url: `${BaseUrl}/admin/Subject/delCourse`,
        method: 'post',
        data
    })
}

export function order_course(data) {
    return service({
        url: `${BaseUrl}/admin/Subject/orderCourse`,
        method: 'post',
        data
    })
}

export function get_problems(data) {
    return service({
        url: `${BaseUrl}/admin/Subject/getProblems`,
        method: 'post',
        data
    })
}

export function add_problems(data) {
    return service({
        url: `${BaseUrl}/admin/Subject/addProblems`,
        method: 'post',
        data
    })
}

export function edit_problems(data) {
    return service({
        url: `${BaseUrl}/admin/Subject/editProblems`,
        method: 'post',
        data
    })
}

export function del_problems(data) {
    return service({
        url: `${BaseUrl}/admin/Subject/delProblems`,
        method: 'post',
        data
    })
}

export function order_Problems(data) {
    return service({
        url: `${BaseUrl}/admin/Subject/orderProblems`,
        method: 'post',
        data
    })
}

//课程表管理
export function get_subject_cate() {
    return service({
        url: `${BaseUrl}/admin/Subject/getCates`,
        method: 'post',
    })
}

export function get_class_detail(data) {
    return service({
        url: `${BaseUrl}/admin/Subject/getBagSubject`,
        method: 'post',
        data
    })
}

export function get_subject_detail(data) {
    return service({
        url: `${BaseUrl}/admin/Subject/detailBagSubject`,
        method: 'post',
        data
    })
}

//上传通用讲义
export function editSet(data) {
    return service({
        url: `${BaseUrl}/admin/SystemSet/editSet`,
        method: 'post',
        data
    })
}

//huoqu 通用讲义
export function getSet() {
    return service({
        url: `${BaseUrl}/admin/SystemSet/getSet`,
        method: 'post',
    })
}

// 添加专业
export function getProfessional() {
    return service({
        url: `${BaseUrl}/admin/SystemSet/getPro`,
        method: 'post',
    })
}

//huoqu 通用讲义
export function addProfessional(data) {
    return service({
        url: `${BaseUrl}/admin/SystemSet/addPro`,
        method: 'post',
        data
    })
}

//huoqu 通用讲义
export function delProfessional(data) {
    return service({
        url: `${BaseUrl}/admin/SystemSet/delPro`,
        method: 'post',
        data
    })
}

//获取学校
export function getSchool() {
    return service({
        url: `${BaseUrl}/admin/SystemSet/getSchool`,
        method: 'post',
    })
}

//添加学校
export function addSchool(data) {
    return service({
        url: `${BaseUrl}/admin/SystemSet/addSchool`,
        method: 'post',
        data
    })
}

//删除学校
export function delSchool(data) {
    return service({
        url: `${BaseUrl}/admin/SystemSet/delSchool`,
        method: 'post',
        data
    })
}

//添加大分类
export function addBPid(data) {
    return service({
        url: `${BaseUrl}/admin/SystemSet/addBpid`,
        method: 'post',
        data
    })
}

//获取大分类
export function getBPid(data) {
    return service({
        url: `${BaseUrl}/admin/SystemSet/getBpid`,
        method: 'post',
        data
    })
}

//添加大分类
export function delBpid(data) {
    return service({
        url: `${BaseUrl}/admin/SystemSet/delBpid`,
        method: 'post',
        data
    })
}

export function getGoodsCate(data) {
    return service({
        url: `${BaseUrl}/admin/User/getGoodsCate`,
        method: 'get',
    })
}

export function addGoodsCate(data) {
    return service({
        url: `${BaseUrl}/admin/User/addGoodsCate`,
        method: 'post',
        data
    })
}

export function editGoodsCate(data) {
    return service({
        url: `${BaseUrl}/admin/User/editGoodsCate`,
        method: 'post',
        data
    })
}

export function delGoodsCate(data) {
    return service({
        url: `${BaseUrl}/admin/User/delGoodsCate`,
        method: 'post',
        data
    })
}

export function addBasicSet(data) {
    return service({
        url: `${BaseUrl}/admin/User/basic`,
        method: 'post',
        data
    })
}

export function getBasicSet(data) {
    return service({
        url: `${BaseUrl}/admin/User/basicDetail`,
        method: 'post',
        data
    })
}

export function addQuestionSet(data) {
    return service({
        url: `${BaseUrl}/admin/User/questionBank`,
        method: 'post',
        data
    })
}

export function getQuestionSet(data) {
    return service({
        url: `${BaseUrl}/admin/User/questionDetail`,
        method: 'post',
        data
    })
}

//保存为详情模板
export function addDetailModel(data) {
    return service({
        url: `${BaseUrl}/admin/User/addTemplate`,
        method: 'post',
        data
    })
}

export function getDetailModel() {
    return service({
        url: `${BaseUrl}/admin/User/templateList`,
        method: 'post',
    })
}

export function delDetailModel(data) {
    return service({
        url: `${BaseUrl}/admin/User/delTemplate`,
        method: 'post',
        data
    })
}

//查询科目下试卷
export function getExam(data) {
    return service({
        url: `${BaseUrl}/admin/User/goodTaskList`,
        method: 'post',
        data
    })
}

export function addExam(data) {
    return service({
        url: `${BaseUrl}/admin/User/addGoodTask`,
        method: 'post',
        data
    })
}

export function editExam(data) {
    return service({
        url: `${BaseUrl}/admin/User/editGoodTask`,
        method: 'post',
        data
    })
}

export function delExam(data) {
    return service({
        url: `${BaseUrl}/admin/User/delGoodTask`,
        method: 'post',
        data
    })
}

export function orderExam(data) {
    return service({
        url: `${BaseUrl}/admin/User/orderGoodTask`,
        method: 'post',
        data
    })
}

//获取商品分类下的科目
export function getGList(data) {
    return service({
        url: `${BaseUrl}/admin/User/gtList`,
        method: 'post',
        data
    })
}

//添加商品分类下的科目
export function addGList(data) {
    return service({
        url: `${BaseUrl}/admin/User/addGt`,
        method: 'post',
        data
    })
}

//删除商品分类下的科目
export function delGList(data) {
    return service({
        url: `${BaseUrl}/admin/User/delGt`,
        method: 'post',
        data
    })
}

export function addStudyHelp(data) {
    return service({
        url: `${BaseUrl}/admin/User/addStudyHelp`,
        method: 'post',
        data
    })
}

export function editStudyHelp(data) {
    return service({
        url: `${BaseUrl}/admin/User/editStudyHelp`,
        method: 'post',
        data
    })
}

export function delStudyHelp(data) {
    return service({
        url: `${BaseUrl}/admin/User/delStudyHelp`,
        method: 'post',
        data
    })
}

//学员管理
export function getUserList(data) {
    return service({
        url: `${BaseUrl}/admin/User/wechatUserList`,
        method: 'post',
        data
    })
}

//学员绑定科目
export function bindUserCate(data) {
    return service({
        url: `${BaseUrl}/admin/User/userBindCate`,
        method: 'post',
        data
    })
}

//学员绑定科目列表
export function getUserCateList(data) {
    return service({
        url: `${BaseUrl}/admin/User/userBindList`,
        method: 'post',
        data
    })
}

//下载word卷子
export function getDocPaper(data){
    return service({
        url:`${BaseUrl}/admin/User/download`,
        method: 'post',
        data
    })
}

//采集科目
export function batchGatherPaper(data) {
    return service({
        url: `${BaseUrl}/admin/Analysis/batchGather`,
        method: 'post',
        data
    })
}
//采集操作
export function gatherPaper(data) {
    return service({
        url: `${BaseUrl}/admin/Analysis/gatherPaper`,
        method: 'post',
        data
    })
}
