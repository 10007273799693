<template>
    <div class="taskDrawer">
        <div class="clearfix">
            <span class="title csP" @click="$router.push('/analysis/students')"><a-icon type="left"
                                                                                style="color:#6a87e6;margin-right:10px"/>采集卷子</span>
        </div>
        <div style="padding-top:20px">
            <a-form layout="vertical" hideRequiredMark>
                <div class="inputBox">
                    <span class="point"></span>
                    <div class="inputWrap">
                        <a-form-item>
                            <a-cascader :fieldNames="{ label: 'cname', value: 'id', children: 'son' }"
                                        :defaultValue="defaultValue" :options="options" @change="onChange"
                                        placeholder="请选择试卷分类" class="noBorder"/>
                        </a-form-item>
                    </div>
                </div>
                <div class="flex2">
                    <div class="inputBox">
                        <span class="point"></span>
                        <div class="inputWrap">
                            <a-form-item>
                                <a-input placeholder="输入大标题,空为采集的标题" class="noBorder" v-model="add.title"/>
                            </a-form-item>
                        </div>
                    </div>
                </div>
                <div class="inputBox">
                    <span class="point"></span>
                    <div class="inputWrap">
                        <a-form-item>
                            <a-textarea :rows="3" placeholder="试卷注意事项" class="noBorder" v-model="add.paper_care"/>
                        </a-form-item>
                    </div>
                </div>
                <div class="flex2">
                    <div class="inputBox">
                        <span class="point"></span>
                        <div class="inputWrap">
                            <a-form-item>
                                <a-input placeholder="登陆用户的Cookie，如为空有的答案会是错误" class="noBorder" v-model="add.cookie"/>
                            </a-form-item>
                        </div>
                    </div>
                </div>
                <div class="flex2">
                    <div class="inputBox">
                        <span class="point"></span>
                        <div class="inputWrap">
                            <a-form-item>
                                <a-input placeholder="输入课程链接的第一页,不要带id参数" class="noBorder" v-model="add.address"/>
                            </a-form-item>
                        </div>
                    </div>
                </div>
                <div>
                </div>
                <div style="text-align:center">
                    <a-button class="btn" :loading="clicked" @click="submit" type="primary" style="width:120px">
                        {{btnText}}
                    </a-button>
                </div>
            </a-form>
        </div>
    </div>
</template>
<script>
    import {getCate, getModelDetail} from '@/libs/api'
    import {gatherPaper} from '@/libs/analysis'

    export default {
        name: 'gather',
        data() {
            return {
                form: this.$form.createForm(this),
                clicked: false,
                btnText: '确认采集',
                modelData: null,
                add: {
                    cate_id: '',
                    cookie : '',
                    address: '',
                    title  : '',
                    paper_care  : '',
                },
                options: [],
                fileUrl: '',
                defaultValue: [],
            };
        },
        computed: {},
        mounted() {
            this.GetCate()
        },
        methods: {
            reset() {
                this.defaultValue = []
                this.add = {
                    cate_id: '',
                    cookie: '',
                    address: '',
                    title  : '',
                    paper_care  : '',
                }
            },
            getCateArr(item) {
                item.forEach(el => {
                    if (el.son.length) {
                        this.getCateArr(el.son)
                    } else {
                        delete el.son
                    }
                });
            },
            GetCate() {
                return new Promise((resolve, reject) => {
                    getCate().then(res => {
                        if (res.length) {
                            this.options = res
                            this.getCateArr(this.options)
                            resolve()
                        }
                    }, error => {
                        console.log(error)
                        reject()
                    })
                })
            },
            onChange(id) {
                this.add.cate_id = id[id.length - 1]
                getModelDetail({cate_id: id[id.length - 1]}).then(res => {
                    this.modelData = res
                }).catch(error => {
                    console.log(error)
                })
            },
            submit() {
                if (!this.add.cate_id) {
                    this.$message.error('请选择学科分类')
                    return false
                }
                if (!this.add.address) {
                    this.$message.error('采集地址不能为空')
                    return false
                }
                this.clicked = true;
                this.btnText = '采集中';
                let formData = new FormData()
                formData.append('cate_id', this.add.cate_id)
                formData.append('cookie', this.add.cookie)
                formData.append('address', this.add.address)
                formData.append('title', this.add.title)
                formData.append('paper_care', this.add.paper_care)
                gatherPaper(formData).then(res => {
                    if(res)
                        this.$message.info(res)
                    this.clicked = false;
                    this.btnText = '确认采集';
                }).catch(error => {
                    console.log(error)
                    this.btnText = '重新采集';
                    this.clicked = false;
                    this.$message.error('采集异常,请从新发起')
                })
            },
        },
    };
</script>
<style lang="less" scoped>
    .taskDrawer {
        padding: 30px;
        position: relative;

        .title {
            font-size: 18px
        }
    }

    .pl35x {
        padding-left: 35px
    }

    .inputBox {
        background: #fff;
        min-height: 82px;
        border-radius: 4px;
        padding: 25px 50px;
        display: flex;
        width: 100%;
        margin-bottom: 25px;
        align-items: center;

        &.grade {
            display: block;
            font-size: 16px;
            color: #999999;
        }

        > * {
            vertical-align: middle
        }

        .point {
            width: 9px;
            height: 9px;
            border-radius: 100%;
            background: #5180e0
        }

        .inputWrap {
            padding-left: 50px;
            flex: 1;

            /deep/ .ant-cascader-picker-label {
                font-size: 16px;
                height: 30px;
            }
        }

        .noBorder /deep/ .ant-input, .noBorder /deep/ .ant-input:focus, .noBorder, .noBorder:focus, .noBorder /deep/ .ant-btn {
            font-size: 16px;
            border: none;
            box-shadow: none
        }
    }

    .flex2 {
        display: flex;
        align-items: center;
        margin-bottom: 25px;

        .inputBox {
            margin: 0;
            flex: 1;
        }
    }

    .ant-form-item {
        margin-bottom: 0;
        padding-bottom: 0
    }

    .gradeSet {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .point {
            margin-right: 50px;
            display: inline-block
        }

        .title {
            color: #999;
            font-size: 16px
        }
    }

    .gradeList {
        display: inline-block;
        vertical-align: middle;
        margin-right: 30px;

        &.mR0 {
            margin-right: 0
        }

        > * {
            display: inline-block;
            vertical-align: middle;
        }

        > .title {
            width: 120px;
            font-size: 16px;
            color: #999999;
            display: inline-block;
            vertical-align: middle;
        }

        .inputWrap {
            border: 1px solid #d9d9d9;
            border-radius: 4px;
            padding-left: 0;
            position: relative;
            display: inline-block;
            height: 40px;
            line-height: 40px;
            width: 120px;
            padding-left: 10px;

            .rightTest {
                position: absolute;
                right: 0;
                width: 50px;
                font-size: 16px;
                color: #999999;
                top: 0;
                line-height: 40px;
                padding-right: 8px;
                text-align: right;

                &.w30x {
                    width: 30px
                }
            }
        }
    }

    .hasText.ant-input {
        font-size: 16px;
        padding-right: 20px;
        height: 40px;
        line-height: 40px
    }

    .tText.hasText {
        padding-right: 50px
    }

    .addtaskType {
        padding: 0 20px;
        height: 35px;
        border-radius: 4px;
        cursor: pointer;
    }
</style>
